import { Link } from "gatsby";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Heading = styled.h1`
    font-weight: 700;
    color: #454F63;    
    font-size: 65px;
    line-height: 1.9375rem;
    margin: 0;
    margin-top: 1em;
`;

const InfoText = styled.p`
    font-weight: 400;
    color: #454F63;    
    font-size: 0.875rem;
    line-height: 1.9375rem;
    margin: 0;
    margin-top: 1em;
`;

const BackLink = styled(Link)`
    font-weight: 400;
    color: #454F63;    
    font-size: 0.875rem;
    line-height: 1.9375rem;
    margin: 0;
    margin-top: 1em;
`;

const Page404 = () => {
    const { t } = useTranslation("common");
    return (
        <Container>
            <Heading>404</Heading>
            <InfoText>{t("404.errormessage")}</InfoText>
            <BackLink to="/">{t("404.return")}</BackLink>
            
        </Container>)
}

export default Page404;